import React from 'react';
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Stack from "@mui/material/Stack";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputNumber from './InputNumber'

import "../App.scss";
import { SALES } from '../constants/commonData'

export default function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);
  const [sale, setSale] = React.useState();
  const [nguoiThu, setNguoiThu] = React.useState();

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {props.index + 1}
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell >{row.phone}</TableCell>
        <TableCell >{row.fare}</TableCell>
        <TableCell >{row.total}</TableCell>
        <TableCell >{row.sale}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Stack
                direction="row"
                className="stackEdit"
                spacing={1}
                justifyContent="space-between"
              >
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  Đóng Lại
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  size="small"
                  onClick={() => props.openModalRemove(row)}
                >
                  Xóa Booking
                </Button>
              </Stack>
              <div>
                <div className="editcT">
                  <TextField
                    className="inputBig name"
                    size="small"
                    value={row.name}
                    id="outlined-controlled margin-none"
                    label=" Tên "
                    onChange={(event) => {
                      props.handleChangeValue(event.target.value, "name", row);
                    }}
                    variant="outlined"
                  />
                  <TextField
                    className="inputBig phone"
                    size="small"
                    value={row.phone}
                    id="outlined-controlled margin-none"
                    label="phone"
                    onChange={(event) => {
                      props.handleChangeValue(event.target.value, "phone", row);
                    }}
                    variant="outlined"
                  />
                </div>
                <div className="editcT">
                  <InputNumber 
                    label="Tổng Tiền "
                    value={row.fare}
                    onChange={(event) => {
                      props.handleChangeValue(event.target.value, "fare", row);
                    }}
                  />

                  <TextField
                    className="inputBig fare"
                    size="small"
                    id="outlined-controlled margin-none"
                    label="Số lượng"
                    type="number"
                    value={row.total}
                    onChange={(event) => {
                      props.handleChangeValue(event.target.value, "total", row);
                    }}
                    variant="outlined"
                  />

                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Sale</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={row.sale}
                      label="Age"
                      onChange={(event) => {
                        props.handleChangeValue(event.target.value, "sale", row);
                      }}
                    >
                      {
                        SALES.map(s => (
                          <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
                <div className="editcT">
                  <TextField
                    className="inputSmall"
                    size="small"
                    value={row.children}
                    id="outlined-controlled margin-none"
                    label="Trẻ em"
                    type="number"
                    onChange={(event) => {
                      props.handleChangeValue(
                        event.target.value,
                        "children",
                        row
                      );
                    }}
                    variant="outlined"
                  />
                  <InputNumber 
                    label="Tiền về tàu"
                    value={row.cruiseFare}
                    onChange={(event) => {
                      props.handleChangeValue(event.target.value, "cruiseFare", row);
                    }}
                  />
                  <FormControl>
                    <InputLabel id="demo-simple-select-label">Người Thu</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={row.nguoiThu}
                      style={{minWidth: '70px'}}
                      label="Người Thu"
                      onChange={(event) => {
                        props.handleChangeValue(event.target.value, "nguoiThu", row);
                      }}
                    >
                      {
                        SALES.map(s => (
                          <MenuItem key={s.value} value={s.value}>{s.label}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </div>
              </div>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}