import * as React from 'react';
import { NumericFormat } from 'react-number-format';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';



const NumericFormatCustom = React.forwardRef(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
        prefix="$"
      />
    );
  },
);

export default function FormattedInputs(props) {

  const handleChange = (event) => {
    // setValues({
    //   ...values,
    //   [event.target.name]: event.target.value,
    // });
    props.onChange(event)
  };

  return (
    <TextField
      label={props.label}
      value={props.value}
      style={props.style}
      className="inputSmall inputBig fare"
      onChange={handleChange}
      name="numberformat"
      id="outlined-controlled margin-none"
      InputProps={{
        inputComponent: NumericFormatCustom,
      }}
      variant="outlined"
    />
  );
}
