import React, { useState, useEffect } from 'react';
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import shortid from "shortid";
import "./App.scss";
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import Paper from '@mui/material/Paper';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Row from './components/Row'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function CollapsibleTable() {
  const [bookings, setBookings] = React.useState([]);
  const [openModal, setOpenModal] = React.useState(false);
  const [valueBottomNav, setValueBottomNav] = React.useState('');
  const [dataBookingRemove, setDataBookingRemove] = React.useState({});
  const [estimateData, setEstimateData] = React.useState({});

  useEffect(() => {
    let bookingsJson = window.localStorage.getItem('bookings');
    let bookings = JSON.parse(bookingsJson);
    if(bookings && bookings.bookings && bookings.bookings.length >= 0) {
      changeBooking(bookings.bookings)
    }
  },[])

  const openModalRemove = (booking) => {
    setOpenModal(true);
    setDataBookingRemove(booking);
  };

  const closeModalRemove = (booking) => {
    setOpenModal(false);
    setDataBookingRemove({});
  };

  const removeBooking = () => {
    setOpenModal(false);
    changeBooking(bookings.filter((bk) => bk.id !== dataBookingRemove.id));
  };

  const handleAddBooking = () => {
    changeBooking([
      ...bookings,
      {
        id: shortid.generate(),
        name: "",
        phone: "",
        fare: 0,
        total: "",
        sale: "",
        children: "",
        cruiseFare: 0,
        nguoiThu: ""
      },
    ]);
  };

  const handleChangeValue = (value, key, row) => {
    let newBooking = bookings.map((bk) => {
      if (bk.id === row.id) {
        bk[key] = value;
      }
      return bk;
    });
    changeBooking(newBooking);
  };

  const changeBooking = (newBooking) => {
    setBookings(newBooking);
    let total = 0,
      children = 0,
      fare = 0,
      adults = 0,
      cruiseFare = 0;
    newBooking.map((bk) => {
      total += parseInt(bk.total || 0);
      adults += parseInt(bk.adults || 0);
      children += parseInt(bk.children|| 0);
      fare += parseInt(bk.fare || 0);
      cruiseFare += parseInt(bk.cruiseFare || 0);
      return bk;
    });
    setEstimateData({
      total,
      children,
      adults,
      fare,
      cruiseFare
    })
  };

  const onChangeBottomNav = (newValue) => {
    console.log(newValue)
    if(newValue === 'create') return handleAddBooking()
    if(newValue === 'save') return saveToServer()
  }

  const saveToServer = () => {
    localStorage.setItem("bookings", JSON.stringify({bookings}));
  }

  return (
    <div id="app">
      <h1> Ngày {'20/04/2022'} </h1>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell >Tên</TableCell>
              <TableCell >ĐT</TableCell>
              <TableCell >Tiền</TableCell>
              <TableCell >Tổng</TableCell>
              <TableCell  >Sale</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {bookings.map((row, index) => (
              <Row
                key={row.id}
                index={index}
                row={row}
                handleChangeValue={handleChangeValue}
                openModalRemove={openModalRemove}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }} elevation={3}>
        <BottomNavigation
          showLabels
          value={''}
          onChange={(event, newValue) => {
            onChangeBottomNav(newValue);
          }}
        >
          <BottomNavigationAction 
            label="Thêm Boooking" 
            icon={<AddCircleOutlineIcon />}
            value="create"
          />
          <BottomNavigationAction 
            label="Lưu" 
            icon={<CloudUploadIcon />} 
            value="save"
          />
        </BottomNavigation>
      </Paper>

      <Modal
        open={openModal}
        onClose={closeModalRemove}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            spiu có chắc muốn xóa không?
          </Typography>
          <Stack
            direction="row"
            className="stackEdit"
            spacing={1}
            justifyContent="space-between"
          >
            <Button
            variant="contained"
            color="error"
            onClick={() => removeBooking()}
            >
              OK Xóa
            </Button>
            <Button variant="contained" onClick={closeModalRemove}>
              Không Xóa
            </Button>
          </Stack>
        </Box>
      </Modal>
      <div id="tonghop">
        <p>Tổng Khách: {estimateData.total}</p>
        <p>Nguoi Lon: {estimateData.adults}</p>
        <p>Tre em: {estimateData.children}</p>
        <p>Tổng tiền: {estimateData.fare}</p>
        <p>Tổng tiền tàu: {estimateData.cruiseFare}</p>
      </div>
    </div>
  );
}
